import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import PrivacyPolicyView from '../components/PrivacyPolicyView';

import '../styles/global.scss';

const NotFoundPage = () => (
  <Layout view="privacy-policy">
    <Seo title="Privacy Policy" />
    <PrivacyPolicyView />
  </Layout>
);

export default NotFoundPage;
